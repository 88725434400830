import React from 'react';
import { Document, Page } from 'react-pdf';
import Resume from '../assets/resume.pdf';
import pdfjsWorker from '../../node_modules/react-pdf/src/pdf.worker.entry'
import 'bootstrap/dist/css/bootstrap.min.css';

class ResumePage extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    numPages: null,
    pageNumber: 1,
    scaleValue: 1
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  render() {
    const { pageNumber, numPages, scaleValue } = this.state;

    return (
      <div>
        <Document
          file={Resume}
          options={{ workerSrc: pdfjsWorker }}
          onLoadSuccess={this.onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page scale={1.3} pageNumber={pageNumber} />
        </Document>
        <div>
          <ul>
            <li>TODO: Provide rudimentary button controls to allow user to adjust scale.</li>
            <li><del>TODO:</del> Provide <a href={Resume}>a direct link</a> to the LaTeX generated PDF file this was rendered from.</li>
          </ul>
          <p>If you're reading this/viewing this page, please forgive me for how rough around the edges it looks. This legacy URL used to link directly to the PDF the image above was rendered from. Due to a recent (as of September 2019) site
            overhaul, this is no longer technically feasible. This is a temporary solution that I wrote in a hurry for those looking for my resume.</p>
          <p>In the meantime, <a href="https://www.chrisolin.com">check out the rest of the site</a> or reach out to me directly if you require a PDF copy.</p>
        </div>
      </div>
    );
  }
}

export default ResumePage
